@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
* {
  margin: 0rem;
  font-family: "Open sans", sans-serif;
}

@media screen and (max-width: 600px) {
  * {
    font-size: 1rem;
  }
}

table,
th,
td {
  border: 1px solid black;
}

.form-input {
  margin-right: 0.5rem;
}

.form-credantial {
  border-width: 1px;
  outline: none;
  padding: 0.4rem;
  border: 1px solid rgb(192, 192, 192);
}

.radio-option {
  display: flex;
  justify-content: flex-start;
}

.form-privateData {
  display: flex;
}

.simulate-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.simulate-button {
  font-size: larger;
  color: white;
  background-color: #f28024;
  outline: none;
  border-width: 0;
  padding: 1rem;
  margin: 2rem;
}

.simulate-button:hover {
  background-color: #ff7300;
  font-weight: bolder;
}

input[type="checkbox"] {
  zoom: 1.5;
}
input[type="radio"] {
  zoom: 1.5;
}

@media only screen and (max-width: 576px) {
  .form-size {
    visibility: hidden;
  }
}

.formLine-row {
  display: flex;
  align-items: center;
  margin: 0.8rem 0;
}

.formLine-name {
  text-align: end;
}

label {
  white-space: pre-wrap;
}

@media only screen and (max-width: 576px) {
  .formLine-name {
    text-align: center;
    margin: 0.5rem 0;
  }
  .formLine-label {
    margin: 0.5rem 0;
  }

  input[type="radio"] {
    zoom: 1.7;
  }
}

.admin-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-form {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.admin-button {
  color: white;
  background-color: #f28024;
  outline: none;
  border-width: 0;
  padding: 1rem;
  margin: 1rem;
  align-self: center;
  display: flex;
  align-items: center;
}

.admin-button-save {
  color: white;
  background-color: #db880c;
  outline: none;
  border-width: 0;
  padding: 1rem;
  margin: 1rem;
  align-self: center;
  display: flex;
  align-items: center;
}

.admin-button:hover {
  background-color: #ff7300;
  font-weight: bolder;
}

.admin-deleteButton {
  background-color: #e74c3c;
  color: white;
  outline: none;
  padding: 0.5rem;
  margin: 1rem;
  border: none;
  display: flex;
  align-items: center;
}

.admin-deleteButton:hover {
  background-color: #ff1900;
  font-weight: bolder;
}

.admin-addPresta {
  background-color: #e74c3c;
  color: white;
  outline: none;
  padding: 0.5rem;
  margin: 0.3rem;
  border: none;
}

td,
th,
tr {
  text-align: center;
}

