.form-input {
  margin-right: 0.5rem;
}

.form-credantial {
  border-width: 1px;
  outline: none;
  padding: 0.4rem;
  border: 1px solid rgb(192, 192, 192);
}

.radio-option {
  display: flex;
  justify-content: flex-start;
}

.form-privateData {
  display: flex;
}

.simulate-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.simulate-button {
  font-size: larger;
  color: white;
  background-color: #f28024;
  outline: none;
  border-width: 0;
  padding: 1rem;
  margin: 2rem;
}

.simulate-button:hover {
  background-color: #ff7300;
  font-weight: bolder;
}

input[type="checkbox"] {
  zoom: 1.5;
}
input[type="radio"] {
  zoom: 1.5;
}

@media only screen and (max-width: 576px) {
  .form-size {
    visibility: hidden;
  }
}
