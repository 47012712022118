.admin-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-form {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.admin-button {
  color: white;
  background-color: #f28024;
  outline: none;
  border-width: 0;
  padding: 1rem;
  margin: 1rem;
  align-self: center;
  display: flex;
  align-items: center;
}

.admin-button-save {
  color: white;
  background-color: #db880c;
  outline: none;
  border-width: 0;
  padding: 1rem;
  margin: 1rem;
  align-self: center;
  display: flex;
  align-items: center;
}

.admin-button:hover {
  background-color: #ff7300;
  font-weight: bolder;
}

.admin-deleteButton {
  background-color: #e74c3c;
  color: white;
  outline: none;
  padding: 0.5rem;
  margin: 1rem;
  border: none;
  display: flex;
  align-items: center;
}

.admin-deleteButton:hover {
  background-color: #ff1900;
  font-weight: bolder;
}

.admin-addPresta {
  background-color: #e74c3c;
  color: white;
  outline: none;
  padding: 0.5rem;
  margin: 0.3rem;
  border: none;
}

td,
th,
tr {
  text-align: center;
}
