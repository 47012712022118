.formLine-row {
  display: flex;
  align-items: center;
  margin: 0.8rem 0;
}

.formLine-name {
  text-align: end;
}

label {
  white-space: pre-wrap;
}

@media only screen and (max-width: 576px) {
  .formLine-name {
    text-align: center;
    margin: 0.5rem 0;
  }
  .formLine-label {
    margin: 0.5rem 0;
  }

  input[type="radio"] {
    zoom: 1.7;
  }
}
