* {
  margin: 0rem;
  font-family: "Open sans", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

@media screen and (max-width: 600px) {
  * {
    font-size: 1rem;
  }
}

table,
th,
td {
  border: 1px solid black;
}
